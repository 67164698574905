import FormBase from 'components/form-base/FormBase';
import { Error, FieldRow, FieldRowDouble } from 'components/form-base/styled';
import { useDirections, useInternshipSettings } from 'contexts';
import DOMPurify from 'dompurify';
import { PhoneNumber, Select, TextField } from 'kl-b2c-ui-kit';
import React, { FC, useEffect } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Register } from 'types';
import { EnglishLevel } from 'types/enums/english-level';
import { HoursPerWeek } from 'types/enums/hours-per-week';

interface RegistrationFormProps {
    submit: (data: FieldValues) => void;
}

const RegistrationForm: FC<RegistrationFormProps> = ({ submit }) => {
    const { t } = useTranslation('pages/home');
    const { internshipSettings, addInternshipResponseLoading } = useInternshipSettings();
    const { directions } = useDirections();

    const {
        control,
        formState: { errors },
        reset,
    } = useFormContext<Register>();

    const isServiceDesk = () => window.location.pathname.includes('service-desk');

    useEffect(
        () => () => {
            reset();
        },
        []
    );

    if (!directions) {
        return null;
    }

    return (
        <FormBase
            gap={'24px'}
            policies={[
                {
                    name: 'agreement.privacyPolicyIsSelected',
                    label: t('pp', {
                        confirmationLink: `${window.location.origin}/privacy-policy#personalDataInternshipProcessing`,
                        ppLink: '/privacy-policy',
                    }),
                    required: true,
                },
                {
                    name: 'agreement.touCheckBoxIsSelected',
                    label: t('tou', {
                        touLink: `${window.location.origin}/terms-of-use`,
                    }),
                    required: true,
                },
                {
                    name: 'agreement.marketingIsSelected',
                    label: t('marketing', {
                        confirmationLink: `${window.location.origin}/privacy-policy#personalDataInternshipProcessing`,
                        ppLink: '/privacy-policy',
                    }),
                },
            ]}
            submit={submit}
            loading={addInternshipResponseLoading}
            submitLabel={t('register')}
        >
            {internshipSettings?.interestDirectionIsRequired && (
                <Controller
                    name={'directionsId'}
                    control={control}
                    rules={{ required: t('required-field') }}
                    defaultValue={isServiceDesk() ? [directions[0].id] : undefined}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <Select
                                isMulti
                                maxLength={3}
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                disabled={isServiceDesk()}
                                defaultValues={
                                    isServiceDesk() ? [{ key: directions[0].name, value: directions[0].id }] : undefined
                                }
                                onChoose={(chosen) => {
                                    onChange(chosen?.map((c) => c.value));
                                }}
                                items={
                                    directions?.map((direction) => ({ key: direction.name, value: direction.id })) || []
                                }
                                placeholder={t('directionsId')}
                                error={!!errors['directionsId']}
                            />
                            {errors?.directionsId && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.directionsId?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.firstNameIsRequired && (
                <Controller
                    name={'firstName'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        maxLength: { value: 50, message: t('max-length', { value: 50 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('first-name')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['firstName']}
                            />
                            {errors?.firstName && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.firstName?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.lastNameIsRequired && (
                <Controller
                    name={'lastName'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        maxLength: { value: 50, message: t('max-length', { value: 50 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('last-name')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['lastName']}
                            />
                            {errors?.lastName && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.lastName?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.emailIsRequired && (
                <Controller
                    name={'email'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        maxLength: { value: 255, message: t('max-length', { value: 255 }) },
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('email-format'),
                        },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('email')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['email']}
                            />
                            {errors?.email && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.email?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.phoneIsRequired && (
                <Controller
                    name={'phone'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        // @ts-ignore
                        validate: (phone: string) => {
                            // 12 is the length of output data with additional plus as first letter
                            if (phone.replace(/\s/g, '').length < 12) {
                                return t('min-length', { value: 11 });
                            }

                            if (phone.replace(/\s/g, '').length > 12) {
                                return t('max-length', { value: 11 });
                            }

                            return true;
                        },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <PhoneNumber
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('phone')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['phone']}
                            />
                            {errors?.phone && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.phone?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.telegramNickNameIsRequired && (
                <Controller
                    name={'telegramNickName'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        maxLength: { value: 255, message: t('max-length', { value: 255 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('telegramNickName')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['telegramNickName']}
                            />
                            {errors?.telegramNickName && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.telegramNickName?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.cityIsRequired && (
                <Controller
                    name={'city'}
                    control={control}
                    rules={{
                        required: t('required-field'),
                        maxLength: { value: 50, message: t('max-length', { value: 50 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('city')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['city']}
                            />
                            {errors?.city && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.city?.message ?? DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.educationIsRequired && (
                <>
                    <Controller
                        name={'university'}
                        control={control}
                        rules={{
                            required: t('required-field'),
                            maxLength: { value: 255, message: t('max-length', { value: 255 }) },
                        }}
                        render={({ field: { onChange, value } }) => (
                            <FieldRow>
                                <TextField
                                    fullWidth
                                    value={value}
                                    type={'text'}
                                    variant={'border'}
                                    transparent
                                    placeholder={t('university')}
                                    onChange={(value) => onChange(value)}
                                    error={!!errors['university']}
                                />
                                {errors?.university && (
                                    <Error>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    errors?.university?.message ??
                                                    DOMPurify.sanitize(t('validations:default')),
                                            }}
                                        />
                                    </Error>
                                )}
                            </FieldRow>
                        )}
                    />
                    <Controller
                        name={'faculty'}
                        control={control}
                        rules={{
                            required: t('required-field'),
                            maxLength: { value: 255, message: t('max-length', { value: 255 }) },
                        }}
                        render={({ field: { onChange, value } }) => (
                            <FieldRow>
                                <TextField
                                    fullWidth
                                    value={value}
                                    type={'text'}
                                    variant={'border'}
                                    transparent
                                    placeholder={t('faculty')}
                                    onChange={(value) => onChange(value)}
                                    error={!!errors['faculty']}
                                />
                                {errors?.faculty && (
                                    <Error>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    errors?.faculty?.message ??
                                                    DOMPurify.sanitize(t('validations:default')),
                                            }}
                                        />
                                    </Error>
                                )}
                            </FieldRow>
                        )}
                    />
                    <FieldRowDouble>
                        <Controller
                            name={'startEducationStr'}
                            control={control}
                            rules={{ required: t('required-field') }}
                            render={({ field: { onChange, value } }) => (
                                <FieldRow>
                                    <Select
                                        items={
                                            internshipSettings?.startEducationYears?.map((item) => ({
                                                key: item,
                                                value: item,
                                            })) || []
                                        }
                                        variant={'border'}
                                        fullWidth
                                        value={value}
                                        placeholder={t('startEducationStr')}
                                        onChoose={(chosen) => {
                                            if (chosen) {
                                                onChange(chosen[0].value);
                                            }
                                        }}
                                        error={!!errors['startEducationStr']}
                                    />
                                    {errors?.startEducationStr && (
                                        <Error>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        errors?.startEducationStr?.message ??
                                                        DOMPurify.sanitize(t('validations:default')),
                                                }}
                                            />
                                        </Error>
                                    )}
                                </FieldRow>
                            )}
                        />
                        <Controller
                            name={'finishEducationStr'}
                            control={control}
                            rules={{ required: t('required-field') }}
                            render={({ field: { onChange, value } }) => (
                                <FieldRow>
                                    <Select
                                        items={
                                            internshipSettings?.finishEducationYears?.map((item) => ({
                                                key: item,
                                                value: item,
                                            })) || []
                                        }
                                        variant={'border'}
                                        fullWidth
                                        value={value}
                                        placeholder={t('finishEducationStr')}
                                        onChoose={(chosen) => {
                                            if (chosen) {
                                                onChange(chosen[0].value);
                                            }
                                        }}
                                        error={!!errors['finishEducationStr']}
                                    />
                                    {errors?.finishEducationStr && (
                                        <Error>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        errors?.finishEducationStr?.message ??
                                                        DOMPurify.sanitize(t('validations:default')),
                                                }}
                                            />
                                        </Error>
                                    )}
                                </FieldRow>
                            )}
                        />
                    </FieldRowDouble>
                </>
            )}

            {internshipSettings?.descriptionYourSelfIsRequired && (
                <Controller
                    name={'descriptionYourSelf'}
                    control={control}
                    rules={{
                        maxLength: { value: 2000, message: t('max-length', { value: 2000 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('descriptionYourSelf')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['descriptionYourSelf']}
                                isTextArea
                            />
                            {errors?.descriptionYourSelf && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.descriptionYourSelf?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.whatYouWantToKnowAboutInternshipIsRequired && (
                <Controller
                    name={'whatYouWantToKnowAboutInternship'}
                    control={control}
                    rules={{
                        maxLength: { value: 2000, message: t('max-length', { value: 2000 }) },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <TextField
                                fullWidth
                                value={value}
                                type={'text'}
                                variant={'border'}
                                transparent
                                placeholder={t('whatYouWantToKnowAboutInternship')}
                                onChange={(value) => onChange(value)}
                                error={!!errors['whatYouWantToKnowAboutInternship']}
                                isTextArea
                            />
                            {errors?.whatYouWantToKnowAboutInternship && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.whatYouWantToKnowAboutInternship?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.hoursPerWeekIsRequired && (
                <Controller
                    name={'hoursPerWeek'}
                    control={control}
                    rules={{ required: t('required-field') }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <Select
                                items={Object.keys(HoursPerWeek)
                                    .filter((key) => !isNaN(Number(HoursPerWeek[key as keyof typeof HoursPerWeek])))
                                    .map((hour) => ({
                                        key: t(hour),
                                        value: HoursPerWeek[hour as keyof typeof HoursPerWeek],
                                    }))}
                                variant={'border'}
                                fullWidth
                                value={value}
                                placeholder={t('hoursPerWeek')}
                                onChoose={(chosen) => {
                                    if (chosen) {
                                        onChange(chosen[0].value);
                                    }
                                }}
                                error={!!errors['hoursPerWeek']}
                            />
                            {errors?.hoursPerWeek && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.hoursPerWeek?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
            {internshipSettings?.englishLevelIsRequired && (
                <Controller
                    name={'englishLevel'}
                    control={control}
                    rules={{ required: t('required-field') }}
                    render={({ field: { onChange, value } }) => (
                        <FieldRow>
                            <Select
                                items={Object.keys(EnglishLevel)
                                    .filter((key) => !isNaN(Number(EnglishLevel[key as keyof typeof EnglishLevel])))
                                    .map((level) => ({
                                        key: t(level),
                                        value: EnglishLevel[level as keyof typeof EnglishLevel],
                                    }))}
                                variant={'border'}
                                fullWidth
                                value={value}
                                placeholder={t('englishLevel')}
                                onChoose={(chosen) => {
                                    if (chosen) {
                                        onChange(chosen[0].value);
                                    }
                                }}
                                error={!!errors['englishLevel']}
                            />
                            {errors?.englishLevel && (
                                <Error>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                errors?.englishLevel?.message ??
                                                DOMPurify.sanitize(t('validations:default')),
                                        }}
                                    />
                                </Error>
                            )}
                        </FieldRow>
                    )}
                />
            )}
        </FormBase>
    );
};

export default RegistrationForm;
