import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseWrapper, HeaderButton } from 'styles/common';
import { CWrap, ContentWrap, MessageWrap, BtnWrap } from './styled';

interface Model {
    accept: () => void;
}

const CookieBanner: FC<Model> = ({ accept }) => {
    const { t } = useTranslation('pages/home');

    return (
        <CWrap>
            <BaseWrapper>
                <ContentWrap>
                    <MessageWrap>
                        {t('cookie-message')}
                        <a rel="noreferrer" target="_blank" href="https://www.kaspersky.ru/third-party-tracking">
                            {t('cookie-more')}
                        </a>
                    </MessageWrap>
                    <BtnWrap>
                        <HeaderButton onClick={accept}>{t('accept-close')}</HeaderButton>
                    </BtnWrap>
                </ContentWrap>
            </BaseWrapper>
        </CWrap>
    );
};

export default CookieBanner;
