import { Accordion, IAccordionItem, Icon } from 'kl-b2c-ui-kit';
import React, { FC } from 'react';
import { DefaultTheme, withTheme } from 'styled-components';

interface PrimaryAccordionProps {
    items: IAccordionItem[];
    theme: DefaultTheme;
}

const PrimaryAccordion: FC<PrimaryAccordionProps> = ({ items, theme }) => (
    <Accordion
        distanceBetweenItems={20}
        iconClose={<Icon name={'ShowLess'} color={'primary'} size={18} />}
        iconOpen={<Icon name={'ShowMore'} color={'primary'} size={18} />}
        config={{
            ItemConfig: {
                css: {
                    borderRadius: 8,
                    background: '#00A88E1A',
                    padding: '0px 24px',
                    border: `1px solid ${theme.palette.green}}`,
                    position: 'relative',
                    zIndex: 25,
                    backdropFilter: 'blur(92px)',

                    '@media screen and (max-width: 767px)': {
                        backdropFilter: 'blur(24px)',
                    },
                },
            },
            HeaderConfig: {
                css: {
                    padding: '20px 0px',
                    fontSize: '18px',
                    lineHeight: '21px',
                    fontWeight: 600,
                },
            },
            BodyConfig: {
                css: {
                    padding: '0px 0px 0px 0px',
                },
            },
        }}
        items={items}
    />
);

export default withTheme(PrimaryAccordion);
