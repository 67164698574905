import styled, { css } from 'styled-components';
import { mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Container = styled.div<{ secondary?: boolean }>`
    padding: 90px 0 80px;
    background: rgba(0, 168, 142, 0.1);

    @media (${mediaSm}) {
        padding: 60px 0 300px;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            ${Title} {
                display: none;
            }

            ${TextContainer} {
                justify-content: flex-end;
            }

            @media (${mediaSm}) {
                padding: 380px 0 45px;
            }
        `};
`;

const TitleMixin = css`
    font-size: 72px;
    line-height: 72px;
    font-weight: 500;
    width: 40%;

    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 36px;
    }
`;

export const Title = styled.h3`
    ${TitleMixin}
`;

export const SecondaryTitle = styled.h3`
    ${TitleMixin}
    padding-left: 170px;
    margin: 0 0 60px 0;
    width: initial;

    @media (${mediaSm}) {
        padding-left: 0;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 32px;

    @media (${mediaSm}) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (${mediaXs}) {
        gap: 15px;
    }
`;

export const DescriptionContainer = styled.div`
    width: 60%;

    @media (${mediaSm}) {
        width: 100%;
    }
`;

export const Description = styled.div<{ secondary?: boolean }>`
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
    margin: 0 0 60px 0;
    padding-left: 170px;

    ${({ secondary }) =>
        secondary &&
        css`
            margin: 0 0 40px 0;
        `};

    @media (${mediaSm}) {
        font-size: 21px;
        line-height: 24px;
        margin: 0 0 40px 0;
        max-width: initial;
        padding: 0;
    }
`;
