import React, { useState, FC, useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Title,
    List,
    ListItem,
    ListItemPeriod,
    ListItemLines,
    ListItemLinesUp,
    ListItemLinesCenter,
    ListItemLinesDown,
    ListItemDescription,
    ListItemDescriptionTitle,
    ListItemDescriptionComment,
} from './styled/roadmap';
import { Phase } from 'types';

export interface PhaseDisplay {
    key: string;
    period?: string;
    title?: string;
    comment?: string;
}

interface RoadMapProps {
    phases: Phase[];
    secondary?: boolean;
}

export const RoadMap: FC<RoadMapProps> = ({ phases, secondary }) => {
    const { t } = useTranslation('pages/home');
    const [phasesDisplay, setPhasesDisplay] = useState<PhaseDisplay[]>();

    useEffect(() => {
        if (phases) {
            setPhasesDisplay(
                phases.map((d: Phase) => ({
                    key: d.id,
                    title: d.name,
                    comment: d.comment,
                    period: d.period,
                }))
            );
        }
    }, [phases]);

    if (!phases || phases.length === 0 || !phasesDisplay) {
        return null;
    }

    return (
        <Container>
            <Title>{t('selection-phases')}</Title>
            <List>
                {phasesDisplay &&
                    phasesDisplay.map((item) => (
                        <ListItem key={item.key}>
                            <ListItemPeriod>{item?.period ?? ''}</ListItemPeriod>
                            <ListItemLines>
                                <ListItemLinesUp />
                                <ListItemLinesCenter />
                                <ListItemLinesDown />
                            </ListItemLines>
                            <ListItemDescription secondary={secondary}>
                                <ListItemDescriptionTitle>{item.title}</ListItemDescriptionTitle>
                                {item.comment && (
                                    <ListItemDescriptionComment
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(item.comment),
                                        }}
                                    />
                                )}
                            </ListItemDescription>
                        </ListItem>
                    ))}
            </List>
        </Container>
    );
};

export default RoadMap;
