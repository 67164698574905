import React, { FC } from 'react';
import { Icon } from 'kl-b2c-ui-kit';
import { FooterSocial, FooterSocialsList } from './styled';
import { FooterSocialLinkType } from 'types';

interface Props {
    icons: FooterSocialLinkType[];
}

const SocialLinks: FC<Props> = ({ icons }) => (
    <FooterSocialsList>
        {icons.map(({ link, iconName }) => (
            <FooterSocial key={link} href={link}>
                {/*@ts-ignore*/}
                <Icon name={iconName} size={32} color={'white'} hoverColor={'teal'} />
            </FooterSocial>
        ))}
    </FooterSocialsList>
);

export default SocialLinks;
