import { EMAIL_HELP } from 'consts';
import DOMPurify from 'isomorphic-dompurify';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseWrapper } from 'styles/common';
import { FaqItem, FaqRow } from 'types';
import {
    Answer,
    AnswerIcon,
    Container,
    Content,
    DesktopButton,
    MobileButton,
    Question,
    RowContainer,
    RowContent,
    RowContentItem,
    RowTitle,
    Title,
} from './styled';
import { getFaqRows } from 'helpers/get-faq-rows';

interface FaqProps {
    questions: FaqItem[];
    activeQuestion?: FaqItem;
    setActive: (question: FaqItem) => void;
}

export const Faq: FC<FaqProps> = ({ activeQuestion, questions, setActive }) => {
    const { t } = useTranslation('pages/home');

    return (
        <Container id="FAQ">
            <BaseWrapper>
                <Content>
                    <Title>{t('faq-title')}</Title>
                    <RowContainer>
                        <RowContentItem>
                            {Object.values(getFaqRows()).map((row: FaqRow) => (
                                <RowContent key={row.key}>
                                    {row.title && <RowTitle>{t(`${row.title}`)}</RowTitle>}

                                    {questions
                                        .filter(
                                            (value: FaqItem) =>
                                                value.subject.toLowerCase() === row.key.toLowerCase() && value.visible
                                        )
                                        .map((item: FaqItem) => (
                                            <div key={item.id}>
                                                <Question
                                                    className={item.id === activeQuestion?.id ? 'active' : ''}
                                                    onClick={() => setActive(item)}
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(item.question),
                                                    }}
                                                />

                                                {activeQuestion?.id === item.id && (
                                                    <Answer
                                                        className="isMobile"
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(activeQuestion.answer, {
                                                                ADD_ATTR: ['target'],
                                                            }),
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </RowContent>
                            ))}
                        </RowContentItem>

                        <RowContentItem className="isDesktop">
                            {activeQuestion && (
                                <>
                                    <Question
                                        className="unselectable"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(activeQuestion.question),
                                        }}
                                    />

                                    <Answer
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(activeQuestion.answer, { ADD_ATTR: ['target'] }),
                                        }}
                                    />

                                    <AnswerIcon />

                                    <DesktopButton color={'secondary'} onClick={() => window.open(EMAIL_HELP)}>
                                        {t('ask-question')}
                                    </DesktopButton>
                                </>
                            )}
                        </RowContentItem>

                        <MobileButton color={'secondary'} onClick={() => window.open(EMAIL_HELP)}>
                            {t('ask-question')}
                        </MobileButton>
                    </RowContainer>
                </Content>
            </BaseWrapper>
        </Container>
    );
};

export default Faq;
