import DOMPurify from 'isomorphic-dompurify';
import React, { FC } from 'react';
import { InternshipTeam } from 'types';
import { DirectionDescription, DirectionTeamTitle } from './styled';
import { useTranslation } from 'react-i18next';

interface DirectionTeamProps {
    team: InternshipTeam;
}

const DirectionTeam: FC<DirectionTeamProps> = ({ team }) => {
    const { t } = useTranslation('pages/home');

    return (
        <>
            <DirectionDescription
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(team.description),
                }}
            />
            <DirectionTeamTitle>{t('direction-looking')}</DirectionTeamTitle>
            <DirectionDescription
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(team.content),
                }}
            />
            <DirectionTeamTitle>{t('direction-important')}</DirectionTeamTitle>
            <DirectionDescription
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(team.candidateRequirement),
                }}
            />
            {team.additionalRequirement && (
                <>
                    <DirectionTeamTitle>{t('direction-bonus')}</DirectionTeamTitle>
                    <DirectionDescription
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(team.additionalRequirement),
                        }}
                    />
                </>
            )}
        </>
    );
};

export default DirectionTeam;
