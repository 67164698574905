import React, { FC } from 'react';
import { NativeNavLink } from './styled';
import { HeaderMenu } from 'types';
import { useMobileMenuContext } from 'contexts';

type HeaderLinkProps = HeaderMenu & {
    className?: string;
    level?: number;
    actionClick?: (actionType: string) => void;
};

const HeaderLink: FC<HeaderLinkProps> = (props) => {
    const { title, dataAtSelector, ...rest } = props;
    const { toggleMobileMenu } = useMobileMenuContext();

    return (
        <NativeNavLink
            onClick={() => toggleMobileMenu(false)}
            href={props.path}
            data-at-selector={dataAtSelector}
            {...rest}
        >
            {title}
        </NativeNavLink>
    );
};

export default HeaderLink;
