import React, { FC } from 'react';
import { HeaderProps } from '../Header';
import { MobileWrap, Nav, NavBarCollapse, NavList, NavListItem } from './styled';
import { useMobileMenuContext } from 'contexts/mobile-menu.context';
import HeaderLink from './header-link/HeaderLink';
import { useInternshipSettings } from 'contexts';
import { useTranslation } from 'react-i18next';
import { HeaderButton } from 'styles/common';

export const HeaderNav: FC<HeaderProps> = ({ links }) => {
    const { isMobileMenuOpened } = useMobileMenuContext();
    const { internshipSettings, showRegistrationModal } = useInternshipSettings();
    const { t } = useTranslation('pages/home');

    return (
        <Nav data-at-selector="pageHeader" isHidden={!isMobileMenuOpened}>
            <NavBarCollapse collapse={!isMobileMenuOpened}>
                <MobileWrap>
                    <NavList>
                        {links.map((item) => (
                            <NavListItem key={item.title}>
                                <HeaderLink {...item} />
                            </NavListItem>
                        ))}
                        {internshipSettings?.isOpened && (
                            <NavListItem>
                                <HeaderButton variant={'outlined'} color={'secondary'} onClick={showRegistrationModal}>
                                    {t('register')}
                                </HeaderButton>
                            </NavListItem>
                        )}
                    </NavList>
                </MobileWrap>
            </NavBarCollapse>
        </Nav>
    );
};
