import React, { FC, ReactElement } from 'react';
import { BaseWrapper } from 'styles/common';
import { Phase } from 'types';
import RoadMap from './RoadMap';
import { Container, Description, DescriptionContainer, TextContainer, Title, SecondaryTitle } from './styled';

interface PhasesProps {
    config: {
        title: string;
        description: string;
        mainImage: ReactElement;
        kumaImage: ReactElement;
    };
    phases: Phase[];
    secondary?: boolean;
}

const Phases: FC<PhasesProps> = ({ config: { title, description, mainImage, kumaImage }, phases, secondary }) => (
    <Container id="calendar" secondary={secondary}>
        <BaseWrapper styles={{ position: 'relative' }}>
            {mainImage}
            <TextContainer>
                <Title>{title}</Title>
                <DescriptionContainer>
                    <Description secondary={secondary}>{description}</Description>
                    {secondary && <SecondaryTitle>{title}</SecondaryTitle>}
                    <RoadMap phases={phases} secondary={secondary} />
                </DescriptionContainer>
            </TextContainer>
            {kumaImage}
        </BaseWrapper>
    </Container>
);

export default Phases;
