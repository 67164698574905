export interface FaqItem {
    id: string;
    number: number;
    question: string;
    answer: string;
    visible: boolean;
    subject: FaqType;
}

export interface FaqRow {
    key: string;
    title?: string;
}

export enum FaqType {
    Selection = 'Selection',
    Internship = 'Internship',
}
