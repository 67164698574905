import { gql } from '@apollo/client';

export const GET_DIRECTIONS_GQL = gql`
    query ($page: Int!, $size: Int!) {
        directions {
            get(filter: { page: $page, size: $size }) {
                count
                items {
                    id
                    name
                    description
                    number
                    visible
                    creationDate
                    link
                    type {
                        id
                        name
                        creationDate
                    }
                    internshipTeams {
                        id
                        name
                        description
                        content
                        candidateRequirement
                        additionalRequirement
                        creationDate
                    }
                }
            }
        }
    }
`;

export default GET_DIRECTIONS_GQL;
