import styled from 'styled-components';
import { colorWhite } from '../../../../theme/theme-helper-selectors';

export const HamburgerLine = styled.div`
    width: 18px;
    height: 3px;
    opacity: 1;
    background-color: ${colorWhite};
    transition: 0.3s;
`;
