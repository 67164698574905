import styled from 'styled-components';
import {
    colorBackground,
    colorWhite,
    headerHeight,
    mediaSm,
    mediaXs,
    transitionBase,
} from '../../../../theme/theme-helper-selectors';
import { DropdownContent } from '../../header-menu-dropdown/styled';

export const NavListItem = styled.li`
    display: flex;
    align-items: center;
    vertical-align: middle;
    color: ${colorWhite};
    font-size: 14px;
    height: ${headerHeight};
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 3px;
        background: transparent;
        transition: 0.3s;
        width: 100%;
        left: 0;
        border-radius: 10px;
    }

    &:hover {
        &:after {
            width: 100%;
            left: 0;
            background: ${colorWhite};
        }
    }

    &:has(button) {
        &:hover {
            &:after {
                display: none;
            }
        }
    }

    @media (${mediaSm}) {
        &:after {
            display: none;
        }

        border-left: 3px solid ${colorBackground};
        transition: ${transitionBase};

        &:hover {
            border-left: 3px solid ${colorWhite};
            background: ${({ theme }) => theme.palette.gray['700']};
        }

        &:last-of-type {
            &:hover {
                border-left: 3px solid ${colorBackground};
                background: transparent;
            }
            button {
                margin: 0 auto;
            }
        }
    }

    @media (${mediaSm}) {
        &,
        ${DropdownContent} {
            margin: 0;
            width: 100%;
            border-bottom: 2px solid ${colorBackground};
            min-height: ${headerHeight};
            padding: 0 32px;
            text-align: center;
            font-size: 18px;
            line-height: 21px;
        }
    }
    @media (${mediaXs}) {
        &,
        ${DropdownContent} {
            padding: 0 24px;
        }
    }
`;
