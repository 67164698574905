import styled, { css } from 'styled-components';
import { PrimaryButton } from 'styles/common';
import { colorPrimary, colorWhite, mediaLg, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const Container = styled.div<{ secondary?: boolean }>`
    position: relative;
    padding: 200px 0 100px 0;
    z-index: 10;

    @media (${mediaSm}) {
        padding: 60px 0 100px 0;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            ${Title} {
                margin: 0 0 20px 0;
                white-space: pre;

                @media (${mediaLg}) {
                    white-space: normal;
                }
            }

            ${BackDrop} {
                height: calc(110px + 100%);

                @media (${mediaLg}) {
                    height: calc(60px + 100%);
                }
            }
        `};
`;

export const TitleContainer = styled.div`
    position: relative;
`;

export const Title = styled.h1`
    font-size: 108px;
    line-height: 108px;
    font-weight: 600;
    color: ${colorWhite};
    max-width: 520px;
    margin: 0 0 80px 0;

    @media (${mediaSm}) {
        font-size: 81px;
        line-height: 81px;
        margin: 0 0 60px 0;
        max-width: 400px;
    }

    @media (${mediaXs}) {
        font-size: 54px;
        line-height: 54px;
    }
`;

export const Subtitle = styled.p`
    font-size: 32px;
    line-height: 36px;
    color: ${colorWhite};
    margin: 0 0 130px 0;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 60px 0;
    }
`;

export const BackDrop = styled.div`
    width: 100%;
    position: absolute;
    top: -26px;
    left: 0;
    height: calc(66px + 100%);
    z-index: -1;
    background: linear-gradient(90deg, ${colorPrimary} 60%, transparent 40%);
    mix-blend-mode: multiply;

    @media (${mediaSm}) {
        background: linear-gradient(90deg, ${colorPrimary} 97%, transparent 3%);
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    @media (${mediaSm}) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        ${PrimaryButton} {
            background: ${colorWhite};
        }
    }
`;

export const DateContainer = styled.p`
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;

    @media (${mediaSm}) {
        font-size: 18px;
        line-height: 21px;
    }
`;
