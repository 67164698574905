import React, { FC, ReactElement } from 'react';
import { Container, Title, BackDrop, TitleContainer, ActionContainer, DateContainer, Subtitle } from './styled';
import { BaseWrapper, PrimaryButton } from 'styles/common';
import { useTranslation } from 'react-i18next';
import { useInternshipSettings } from 'contexts';

interface IntroProps {
    config: {
        title: string;
        until: Date | null;
        isOpened: boolean;
        subtitle?: string;
    };
    introImage: ReactElement;
    secondary?: boolean;
}

const Intro: FC<IntroProps> = ({ config: { title, until, isOpened, subtitle }, secondary, introImage }) => {
    const { t } = useTranslation('pages/home');
    const { showRegistrationModal } = useInternshipSettings();

    return (
        <Container secondary={secondary}>
            <TitleContainer>
                <BaseWrapper
                    styles={
                        secondary
                            ? { '@media screen and (max-width: 767px)': { width: '72%', margin: 'initial ' } }
                            : {}
                    }
                >
                    <Title>{title}</Title>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </BaseWrapper>
                <BackDrop />
            </TitleContainer>
            <BaseWrapper>
                <ActionContainer>
                    {isOpened ? (
                        <>
                            <PrimaryButton
                                variant={'outlined'}
                                color={'primary'}
                                size={'large'}
                                onClick={showRegistrationModal}
                            >
                                {t('register')}
                            </PrimaryButton>
                            {until && (
                                <DateContainer>
                                    {t('until', {
                                        date: new Date(until).toLocaleString('ru-RU', {
                                            day: 'numeric',
                                            month: 'long',
                                        }),
                                    })}
                                </DateContainer>
                            )}
                        </>
                    ) : (
                        <DateContainer>{t('closed')}</DateContainer>
                    )}
                </ActionContainer>
                {introImage}
            </BaseWrapper>
        </Container>
    );
};

export default Intro;
