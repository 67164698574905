import styled, { css } from 'styled-components';
import { colorPrimary } from 'theme/theme-helper-selectors';

export const TabContent = styled.div<{
    backgroundColor?: string;
    textColor?: '';
    rootStyles?: string;
}>`
    background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
    color: ${({ textColor }) => (textColor ? textColor : 'black')};
    ${({ rootStyles }) => rootStyles}
`;
export const TabHeader = styled.div<{ headerStyles?: string }>`
    ${({ headerStyles }) => headerStyles}
`;
export const TabMenu = styled.ul<{ menuStyles?: string }>`
    align-items: center;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
    display: flex;
    list-style: none;
    margin: 0;
    padding: 30px 0 0;
    column-gap: 30px;
    flex-wrap: wrap;
    ${({ menuStyles }) => menuStyles}
`;
export const TabMenuItem = styled.li<{
    isActive?: boolean;
    menuItemStyles?: string;
}>`
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    padding: 12px 0;
    cursor: pointer;
    position: relative;
    ${({ isActive }) =>
        isActive &&
        css`
            border-bottom: 3px solid ${colorPrimary};
        `};
    ${({ menuItemStyles }) => menuItemStyles}
`;
export const TabBody = styled.div<{ bodyStyles?: string }>`
    overflow-y: auto;
    padding: 30px 40px;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    border-top: ${({ theme }) => `2px solid ${theme.palette.gray['700']}`};
    ul {
        list-style: disc;
        line-height: 29px;
        grid-row-gap: 16px;
        display: grid;
    }
    ${({ bodyStyles }) => bodyStyles}
`;
