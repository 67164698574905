import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from '../../../theme/theme-helper-selectors';

export const FooterWrapper = styled.div`
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (${mediaMd}) {
        padding: 16px 0 8px;
    }
    @media (${mediaSm}) {
        flex-direction: column;
        padding: 24px 0 10px;
        align-items: center;
    }
    @media (${mediaXs}) {
        padding: 20px 0 10px;
    }
`;
