import { FooterSocialLinkType, FooterType } from 'types';

const socialLinks: FooterSocialLinkType[] = [
    {
        link: 'https://t.me/kaspersky_career',
        iconName: 'Telegram',
        type: 'telegram',
    },
    {
        link: 'https://www.facebook.com/securelist',
        iconName: 'Facebook',
        type: 'facebook',
        disabled: true,
    },
    {
        link: 'https://www.instagram.com/thesascon/',
        iconName: 'Instagram',
        type: 'instagram',
        disabled: true,
    },
    {
        link: 'https://twitter.com/TheSAScon',
        iconName: 'Twitter',
        type: 'twitter',
        disabled: true,
    },
    {
        link: 'https://www.youtube.com/c/KasperskyTech',
        iconName: 'Youtube',
        type: 'youtube',
    },
    {
        link: 'https://vk.com/kaspersky.team',
        iconName: 'Vk',
        type: 'vk',
    },
];

export const getSocialLinks = () => socialLinks.filter((item) => !item.disabled);

export const footerModel: FooterType = {
    links: [
        {
            title: 'Terms of use',
            isExternal: false,
            dataAtSelector: 'footerTou',
            link: '/terms-of-use/',
            target: '_blank',
        },
        {
            title: 'Privacy policy',
            isExternal: false,
            link: '/privacy-policy/',
            dataAtSelector: 'footerPrivacy',
            target: '_blank',
        },
        {
            title: 'Contacts',
            isExternal: true,
            link: 'https://www.kaspersky.ru/about/contact',
            dataAtSelector: 'footerContacts',
        },
    ],
    copyright: `© ${new Date().getFullYear()} AO Kaspersky. All Rights Reserved`,
    icons: getSocialLinks(),
};
