import styled from 'styled-components';
import { colorGray300 } from '../../../theme/theme-helper-selectors';

export const Copyright = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    align-items: center;
    color: ${colorGray300};
    display: block;
`;
