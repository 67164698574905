import { useQuery } from '@apollo/client';
import GET_DIRECTIONS_GQL from 'api/queries/getDirections';
import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Direction } from 'types';

interface DirectionsContext {
    directions: Direction[] | null;
}

const DirectionsContext = createContext<DirectionsContext>({} as DirectionsContext);

const DirectionsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [directions, setDirections] = useState<Direction[] | null>(null);

    useQuery(GET_DIRECTIONS_GQL, {
        variables: {
            page: 0,
            size: 50,
        },
        onCompleted: ({
            directions: {
                get: { items },
            },
        }) => {
            setDirections(items);
        },
    });

    const memoValue = useMemo(
        () => ({
            directions,
        }),
        [directions]
    );

    return <DirectionsContext.Provider value={memoValue}>{children}</DirectionsContext.Provider>;
};

export const useDirections = () => useContext(DirectionsContext);

export default DirectionsProvider;
