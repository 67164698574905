import { useQuery } from '@apollo/client';
import GET_FAQ_QUESTIONS_GQL from 'api/queries/getFaqQuestions';
import GET_HOMEPAGE_SETTINGS_GQL from 'api/queries/getHomepageSettings';
import GET_INTERNSHIP_STEPS_GQL from 'api/queries/getInternshipSteps';
import { useInternshipSettings } from 'contexts/internship-settings.context';
import { Loader } from 'kl-b2c-ui-kit';
import React, { FC, useState } from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import { FaqItem, HomepageSettings, Phase } from 'types';
import Directions from './components/directions/Directions';
import Faq from './components/faq';
import Intro from './components/intro/Intro';
import Phases from './components/phases/Phases';
import Reviews from './components/reviews/Reviews';
import { IntroImage, Kuma, Snowboarder } from './components/styled';

const HomePage: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const [homepageSettings, setHomepageSettings] = useState<HomepageSettings>();
    const [phases, setPhases] = useState<Phase[]>();
    const [questions, setQuestions] = useState<FaqItem[]>();
    const [activeQuestion, setActiveQuestion] = useState<FaqItem>();
    const { internshipSettings } = useInternshipSettings();

    useQuery(GET_HOMEPAGE_SETTINGS_GQL, {
        onCompleted: ({ homepageSettings: { settings } }) => {
            setHomepageSettings(settings);

            setTimeout(() => {
                const { hash } = window.location;

                const elementToScroll = document.getElementById(hash?.replace('#', ''));

                if (!elementToScroll) return;

                window.scrollTo({
                    top: elementToScroll.offsetTop,
                });
            }, 1000);
        },
    });

    useQuery(GET_INTERNSHIP_STEPS_GQL, {
        variables: {
            page: 0,
            size: 20,
        },
        onCompleted: ({
            phaseSelectionOfIntern: {
                get: { items },
            },
        }) => {
            setPhases(items);
        },
    });

    useQuery(GET_FAQ_QUESTIONS_GQL, {
        variables: {
            page: 0,
            size: 30,
        },
        onCompleted: ({
            questions: {
                get: { items },
            },
        }) => {
            setActiveQuestion(items[0]);
            setQuestions(items);
        },
    });

    if (!phases || !homepageSettings || !internshipSettings || !questions) {
        return <Loader variant="fixed" size="large" transparent customColor={theme.palette.green} />;
    }

    return (
        <>
            <Intro
                config={{
                    title: homepageSettings.introTitle,
                    until: internshipSettings.finish,
                    isOpened: internshipSettings.isOpened,
                }}
                introImage={
                    <IntroImage
                        src={process.env.PUBLIC_URL + '/assets/images/intro-image.png'}
                        alt="Safeboard intro image"
                    />
                }
            />

            <Directions
                config={{
                    description: homepageSettings.directionsDescription,
                    title: homepageSettings.directionsTitle,
                }}
            />

            {phases && (
                <Phases
                    config={{
                        title: homepageSettings.traineeTitle,
                        description: homepageSettings.traineeDescription,
                        mainImage: <Snowboarder src={process.env.PUBLIC_URL + '/assets/images/snowboard.png'} />,
                        kumaImage: <Kuma src={process.env.PUBLIC_URL + '/assets/images/kuma.png'} />,
                    }}
                    phases={phases}
                />
            )}

            <Reviews
                config={{
                    description: homepageSettings.careerStartDescription,
                }}
            />

            {questions.length > 0 && (
                <Faq questions={questions} activeQuestion={activeQuestion} setActive={setActiveQuestion} />
            )}
        </>
    );
};

export default withTheme(HomePage);
