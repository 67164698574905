import { FooterMenuExternalLink, FooterMenuItem, FooterMenuLink, FooterMenuList } from './styled';
import React, { FC } from 'react';
import { FooterLinkType } from 'types';

interface Props {
    links: FooterLinkType[];
    allowCookieBot?: boolean;
}

const FooterMenu: FC<Props> = ({ links }) => (
    <FooterMenuList>
        {links.map(({ link, title, dataAtSelector, isExternal, onClick, target }) => (
            <FooterMenuItem key={link}>
                {isExternal ? (
                    <FooterMenuExternalLink href={link} data-at-selector={dataAtSelector}>
                        {title}
                    </FooterMenuExternalLink>
                ) : (
                    <FooterMenuLink
                        to={link}
                        target={target}
                        onClick={(e) => {
                            if (onClick) {
                                e.stopPropagation();
                                onClick();
                                return false;
                            }
                        }}
                        data-at-selector={dataAtSelector}
                    >
                        {title}
                    </FooterMenuLink>
                )}
            </FooterMenuItem>
        ))}
    </FooterMenuList>
);

export default FooterMenu;
