import { FaqRow, FaqType } from 'types/faq';
import { toDictionary } from './to-dictionary';

export const getFaqRows = (): Record<string, FaqRow> =>
    toDictionary(
        Object.keys(FaqType).map((key: string) => {
            const resource: string = FaqType[key as keyof typeof FaqType].toLowerCase();

            return {
                key,
                title: resource,
            };
        }),
        'key'
    );
