import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { headerMenuItems, footerModel, LS_KEYS } from 'consts';
import Header from '../../components/header/Header';
import Main from '../../components/main/Main';
import Footer from '../../components/footer/Footer';
import { DirectionsProvider, InternshipSettingsProvider } from 'contexts';
import { StorageService } from 'services';
import CookieBanner from 'components/cookie-banner/CookieBanner';

const App = () => {
    const storageService = new StorageService();
    const [cookieBannerVisible, setCookieBannerVisible] = useState<boolean>(
        !storageService.getItem<boolean>(LS_KEYS['cookiesSuppressed'])
    );

    const isServiceDesk = () => window.location.pathname.includes('service-desk');

    const [links, setLinks] = useState(isServiceDesk() ? [headerMenuItems[1], headerMenuItems[3]] : headerMenuItems);

    const location = useLocation();

    useEffect(() => {
        if (isServiceDesk()) {
            setLinks([headerMenuItems[1], headerMenuItems[3]]);
        } else {
            setLinks(headerMenuItems);
        }
    }, [location]);

    return (
        <DirectionsProvider>
            <InternshipSettingsProvider>
                <Header links={links} />
                <Main>
                    <Outlet />
                </Main>
                <Footer model={footerModel} />
                {cookieBannerVisible && (
                    <CookieBanner
                        accept={() => {
                            storageService.setItem(LS_KEYS['cookiesSuppressed'], true);
                            setCookieBannerVisible(false);
                        }}
                    />
                )}
            </InternshipSettingsProvider>
        </DirectionsProvider>
    );
};

export default App;
