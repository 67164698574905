import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AppSettings } from 'types';

export interface Props {
    children: React.ReactNode;
    appSettings: AppSettings;
}

export const ApolloClientLayout: React.FC<Props> = (props): JSX.Element => {
    const {
        children,
        appSettings: { graphQlApiAddress },
    } = props;

    const httpLink = createHttpLink({
        uri: graphQlApiAddress,
    });

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem('token');
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    const client = new ApolloClient({
        cache: new InMemoryCache({
            addTypename: false,
        }),
        link: authLink.concat(httpLink),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientLayout;
