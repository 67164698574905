import { Typography } from 'kl-b2c-ui-kit';
import styled from 'styled-components';
import { colorPrimary } from 'theme/theme-helper-selectors';

export const Paragraph = styled(Typography).attrs({
    variant: 'p1',
})`
    padding: 12px 0;
`;

export const SectionTitle = styled(Typography).attrs({
    variant: 'h2',
})`
    text-transform: uppercase;
`;

export const Title = styled.div`
    padding-bottom: 10px;

    h3 {
        padding: 12px 0;
    }
`;

export const SubTitle = styled.div`
    padding: 20px 0px 10px 0px;
    text-transform: uppercase;
`;

export const Chapter = styled.div`
    padding: 10px 0;
`;

export const CWrap = styled.div`
    padding: 62px 0;

    b {
        font-weight: 500;
    }
`;

export const Table = styled.table`
    border-collapse: collapse;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableData = styled.td`
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.palette.background};
`;

export const NumberList = styled.ol`
    list-style-type: auto;
    padding-left: 20px;
`;

export const PointList = styled.ul`
    list-style-type: disc;
    padding-left: 20px;
`;

export const ListItem = styled.li`
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    padding: 12px 0;
`;

export const HorizontalLine = styled.hr`
    background-color: ${({ theme }) => theme.palette.background};
    margin-top: 20px;
    height: 1px;
    width: 50%;
`;

export const Anchor = styled.a`
    color: ${colorPrimary};
    font-weight: 500;
`;

export const SupText = styled.sup`
    top: -0.5em;
    padding: 0px 3px;
`;

export const TableHeadData = styled.th`
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.palette.background};
    vertical-align: middle;
`;
