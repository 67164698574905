import styled, { css } from 'styled-components';
import { colorPrimary, colorText, mediaSm } from 'theme/theme-helper-selectors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const Title = styled.h2`
    color: ${colorPrimary};
    padding-bottom: 10px;
    margin: 0 0 28px 0;
    font-weight: 400;
    padding-left: 170px;

    @media (${mediaSm}) {
        font-size: 21px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;
        padding: 0;
    }
`;

export const List = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
`;

export const ListItemLinesUp = styled.div`
    border-left: 2px solid ${colorPrimary};
    display: flex;
    height: calc(50% - 6px);
`;

export const ListItemLinesCenter = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: ${colorPrimary};
    overflow: hidden;
`;

export const ListItemLinesDown = styled.div`
    border-left: 2px solid ${colorPrimary};
    display: flex;
    height: calc(50% - 6px);
`;

export const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &:first-of-type {
        ${ListItemLinesUp} {
            border-left: none;
        }
    }
    &:last-of-type {
        ${ListItemLinesDown} {
            border-left: none;
        }
    }
`;

export const ListItemPeriod = styled.div`
    width: 170px;
    text-align: right;
    padding-right: 20px;
    align-self: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;

    @media (${mediaSm}) {
        width: 33%;
    }
`;

export const ListItemLines = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ListItemDescription = styled.div<{ secondary?: boolean }>`
    padding: 20px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;

    ${({ secondary }) =>
        secondary &&
        css`
            padding: 10px 0px 10px 20px;
        `};

    @media (${mediaSm}) {
        width: 50%;
    }
`;

export const ListItemDescriptionTitle = styled.p`
    font-size: 18px;
    line-height: 24px;
`;

export const ListItemDescriptionComment = styled.p`
    color: ${colorText};
    opacity: 0.4;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
`;
