import DOMPurify from 'isomorphic-dompurify';
import React, { FC } from 'react';
import { Review as ReviewModel } from 'types';
import { Avatar, Content, HeaderContainer, Name, Position, TitleContainer } from './styled';

interface ReviewsProps {
    review: ReviewModel;
    autoHeight?: boolean;
    isImpression?: boolean;
}

const Review: FC<ReviewsProps> = ({ review, autoHeight, isImpression }) => (
    <>
        <HeaderContainer>
            <Avatar background={review.image} />
            <TitleContainer>
                <Name>
                    {review.firstName} {review.lastName}
                </Name>
                <Position> {review.position}</Position>
            </TitleContainer>
        </HeaderContainer>
        <Content
            autoHeight={autoHeight}
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(isImpression ? review.impression : review.feedback),
            }}
        />
    </>
);

export default Review;
