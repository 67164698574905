import React, { FC } from 'react';
import { FooterType } from 'types';
import FooterMenu from './FooterMenu';
import { Copyright, FooterRoot, FooterWrapper, LeftSide, RightSide, FooterText, Logo } from './styled';
import SocialLinks from './SocialLinks';
import { BaseWrapper } from 'styles/common';

interface Model {
    model: FooterType;
    allowCookieBot?: boolean;
}

const Footer: FC<Model> = ({ model, allowCookieBot }): JSX.Element => (
    <FooterRoot data-at-selector="pageFooter">
        <BaseWrapper>
            <FooterWrapper>
                <LeftSide>
                    <Logo src={process.env.PUBLIC_URL + '/assets/images/kaspersky.png'} alt="Kaspersky Logo" />
                    <Copyright>{model.copyright}</Copyright>
                    <FooterMenu links={model.links} allowCookieBot={allowCookieBot} />
                </LeftSide>
                <RightSide>
                    {model.text && <FooterText>{model.text}</FooterText>}
                    <SocialLinks icons={model.icons} />
                </RightSide>
            </FooterWrapper>
        </BaseWrapper>
    </FooterRoot>
);

export default Footer;
