import styled from 'styled-components';
import { colorMenuBackground, headerHeight, mediaSm } from '../../../../theme/theme-helper-selectors';

export const NavList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    height: ${headerHeight};
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 32px;
    position: relative;

    @media (${mediaSm}) {
        width: 100%;
        flex-wrap: wrap;
        height: auto;
        overflow: hidden;
        background: ${colorMenuBackground};
        gap: 16px;
    }
`;
