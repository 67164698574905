import styled from 'styled-components';
import { PrimaryButton } from 'styles/common';
import { colorPrimary, colorText, colorWhite, mediaXs, transitionBase } from 'theme/theme-helper-selectors';

export const Container = styled.div`
    position: relative;
    z-index: 10;
    overflow: hidden;
    background: ${colorPrimary};
    color: ${colorWhite};
`;

export const Title = styled.h2`
    margin: 0 0 40px 0;
    font-size: 72px;
    line-height: 72px;
    font-weight: 500;

    @media (${mediaXs}) {
        font-size: 32px;
        line-height: 36px;
        margin: 0 0 30px 0;
    }
`;

export const Content = styled.div`
    padding: 80px 0;
    min-height: 100%;
    position: relative;
    z-index: 10;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 80px;

    @media (${mediaXs}) {
        flex-direction: column;
        gap: 40px;
    }
`;

export const RowContent = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;

    @media (${mediaXs}) {
        gap: 15px;
    }
`;

export const RowTitle = styled.p`
    font-weight: 500;
    margin: 0 0 10px 0;

    font-size: 32px;
    line-height: 36px;

    @media (${mediaXs}) {
        font-size: 21px;
        margin: 0 0 15px 0;
    }
`;

export const RowContentItem = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
    position: relative;
    z-index: 25;

    @media (${mediaXs}) {
        width: 100%;
        gap: 30px;

        &:last-child {
            align-items: flex-end;
        }

        &.isDesktop {
            display: none;
        }
    }
`;

export const Question = styled.div`
    display: inline-block;
    color: ${colorWhite};
    padding: 6px 10px;
    letter-spacing: 1.44px;
    font-size: 18px;
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    transition: ${transitionBase};

    @media (${mediaXs}) {
        &.active {
            &:before {
                content: '';
                width: 13px;
                height: 7px;
                background-image: 'url("/assets/images/home/bubble-tail.svg")';
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                bottom: -8px;
            }
        }
    }

    &:hover {
        box-shadow: -3.74px 3.74px 22.42px 0px #26ffd34d;
    }

    &:hover,
    &.active,
    &.unselectable {
        cursor: pointer;
        background-color: ${colorWhite};
        color: ${colorText};
    }

    &.unselectable {
        padding: 6px 20px;
        pointer-events: none;
        float: right;
        margin: 30px 0 0 0;
        align-self: flex-end;
        position: relative;

        &:before {
            content: ' ';
            width: 13px;
            height: 7px;
            background-image: url('/assets/icons/bubble-tail.svg');
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            bottom: -8px;
            transform: scaleX(-1);
        }

        @media (${mediaXs}) {
            display: none;
        }
    }
`;

export const Answer = styled.div`
    font-size: 18px;
    line-height: 21px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 10px;
    backdrop-filter: blur(6.5px);
    position: relative;
    background: #19b199;

    ul {
        list-style-type: initial;

        li {
            padding: 5px 0;
        }
    }

    a {
        color: ${colorWhite};
        text-decoration: underline;
        font-weight: 500;
    }

    &.isMobile {
        display: none;
    }

    &:before {
        content: '';
        width: 13px;
        height: 7px;
        background-image: url('/assets/icons/bubble-tail-grey.svg');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        bottom: -7px;

        @media (${mediaXs}) {
            transform: scaleX(-1);
            left: auto;
            right: 0;
        }
    }

    @media (${mediaXs}) {
        margin: 25px 0 0 0;

        &.isMobile {
            display: block;
        }
    }
`;

export const AnswerIcon = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -35px;
    background: url('/assets/images/k-logo.png');
`;

export const Wrap = styled.div``;

export const MobileButton = styled(PrimaryButton)`
    display: none;
    width: 100%;
    color: ${colorText};
    font-size: 21px;
    line-height: 24px;

    @media (${mediaXs}) {
        display: block;
        font-size: 18px;
        line-height: 21px;
    }
`;

export const DesktopButton = styled(PrimaryButton)`
    width: 100%;
    color: ${colorText};
    font-size: 21px;
    line-height: 24px;

    @media (${mediaXs}) {
        font-size: 18px;
        line-height: 21px;
    }
`;
