import React, { PropsWithChildren } from 'react';
import { GlobalStyle } from 'styles/global-styles';
import { LangStateProvider, ThemeStateProvider } from 'contexts';
import { GlobalStyles as UiKitGlobalStyles } from 'kl-b2c-ui-kit';
import { NormalizeCss } from 'styles/normalize-css';
import { AppSettings } from 'types/app-settings';
import ApolloClient from 'containers/apollo-client';

const CoreProvider: React.FC<PropsWithChildren<{ appSettings: AppSettings }>> = ({
    children,
    appSettings,
}): JSX.Element => (
    <ApolloClient appSettings={appSettings}>
        <ThemeStateProvider>
            <UiKitGlobalStyles />
            <NormalizeCss />
            <GlobalStyle />
            <LangStateProvider>{children}</LangStateProvider>
        </ThemeStateProvider>
    </ApolloClient>
);

export default CoreProvider;
