import styled, { css } from 'styled-components';
import {
    colorGray,
    colorPrimary,
    colorWhite,
    mediaMd,
    mediaSm,
    mediaXs,
    transitionBase,
} from 'theme/theme-helper-selectors';

export const Container = styled.div`
    position: relative;
    padding: 80px 0 140px 0;

    @media (${mediaSm}) {
        padding: 120px 0 60px 0;
    }
`;

export const TitleContainer = styled.div`
    margin: 0 0 40px 0;

    @media (${mediaSm}) {
        margin: 0 0 15px 0;
    }
`;

export const Title = styled.h3`
    max-width: 665px;
    font-size: 72px;
    line-height: 72px;
    font-weight: 500;

    @media (${mediaSm}) {
        font-size: 32px;
        line-height: 36px;
        max-width: 300px;
    }
`;

export const DescriptionContainer = styled.div`
    margin: 0 0 60px 0;

    @media (${mediaSm}) {
        margin: 0 0 40px 0;
    }
`;

export const Description = styled.p`
    font-size: 32px;
    line-height: 36px;
    padding: 0 40px 0 0;

    @media (${mediaSm}) {
        font-size: 21px;
        line-height: 24px;
    }
`;

export const DirectionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;

    @media (${mediaMd}) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (${mediaXs}) {
        grid-template-columns: 1fr;
    }
`;

export const ArrowContainer = styled.div`
    position: absolute;
    bottom: 16px;
    right: 16px;

    svg {
        fill: transparent;
        stroke: ${colorPrimary};
        opacity: 0.3;
        transition: ${transitionBase};
    }
`;

export const DirectionCard = styled.div<{ isGreen: boolean }>`
    border: 1px solid ${colorPrimary};
    border-radius: 8px;
    padding: 13px 34px 13px 13px;
    transition: ${transitionBase};
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ isGreen }) =>
        isGreen &&
        css`
            color: ${colorWhite};
            background: ${colorPrimary} !important;

            ${DirectionCardTag} {
                color: ${colorWhite};
                opacity: 0.7;
                padding: 0 60px 0 0;
            }

            ${ArrowContainer} {
                svg {
                    stroke: ${colorWhite};
                    opacity: 1;
                }
            }
        `};

    &:hover {
        background: #00a88e1a;

        ${ArrowContainer} {
            svg {
                opacity: 1;
                transform: translateX(5px);
            }
        }
    }
`;

export const DirectionCardTag = styled.span`
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(42, 53, 65, 0.7);
    margin: 0 0 45px 0;
    display: inline-block;
`;

export const DirectionCardName = styled.h4`
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
`;

export const FastTrack = styled.div`
    padding: 2px 7px;
    background: ${colorWhite};
    color: ${colorPrimary};
    position: absolute;
    top: 12px;
    right: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    border-radius: 5px;
`;

export const Brackets = styled.img`
    position: absolute;
    left: -200px;
    top: 10px;

    @media (${mediaSm}) {
        width: 120px;
        left: 0;
        top: -60px;
    }

    @media (${mediaXs}) {
        width: 72px;
        top: 10px;
    }
`;

export const Go = styled.img`
    position: absolute;
    left: -250px;
    top: 500px;

    @media (${mediaSm}) {
        width: 120px;
        left: auto;
        top: 0;
        right: 450px;
    }

    @media (${mediaXs}) {
        width: 87px;
        top: 55px;
        right: 120px;
    }
`;

export const CPlus = styled.img`
    z-index: 10;
    position: absolute;
    right: 120px;
    bottom: -60px;

    @media (${mediaSm}) {
        left: 50%;
    }

    @media (${mediaXs}) {
        transform: translateX(-50%);
        width: 72px;
        bottom: -30px;
    }
`;

export const ModalTitle = styled.h2`
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
    margin: 0 0 15px 0;
`;

export const ModalDisclaimer = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    padding: 8px 0px;
`;

export const DirectionDescription = styled.div`
    font-size: 16px;
    line-height: 18px;
    padding: 20px 0;

    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
        font-weight: 500;
    }
`;

export const DirectionTeamTitle = styled.h4`
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    padding: 12px 0;
`;

export const ModalContainer = styled.div`
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 0 10px 0 0;

    ::-webkit-scrollbar {
        width: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colorGray};
        border-radius: 5px;
    }
`;
