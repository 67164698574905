import styled, { css } from 'styled-components';
import { mediaMd, mediaSm } from '../../theme/theme-helper-selectors';

export const HeaderRoot = styled.header<{ menuOpened?: boolean }>`
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background};
    box-shadow: rgba(24, 39, 75, 0.08) 0px 4px 4px -2px, rgba(24, 39, 75, 0.12) 0px 2px 4px -2px;

    ${({ menuOpened }) =>
        menuOpened &&
        css`
            @media (${mediaSm}) {
                display: none;
            }
        `}
`;

export const HeaderWrapper = styled.div<{ isSticky?: boolean }>`
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    min-height: 64px;

    @media (${mediaMd}) {
        padding: 0 32px;
    }
`;

export const Logo = styled.img`
    width: 108px;
    position: relative;
    z-index: 100;
`;
