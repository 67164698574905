import React, { FC } from 'react';
import { BaseWrapper } from 'styles/common';
import { Anchor, Chapter, CWrap, ListItem, NumberList, Paragraph, SectionTitle, SubTitle, Title } from './styled';
import { Typography } from 'kl-b2c-ui-kit';

const Tou: FC = () => (
    <CWrap className="tou">
        <BaseWrapper>
            <Title>
                <Typography variant="h3">Условия использования веб-сайта safeboard.kaspersky.ru</Typography>
            </Title>
            <Chapter>
                <Paragraph variant="p1">
                    <b>ВНИМАНИЕ!</b> Внимательно ознакомьтесь с настоящими Условиями использования веб-сайта
                    safeboard.kaspersky.ru перед началом его использования.
                </Paragraph>
            </Chapter>
            <SubTitle>
                <SectionTitle variant="h2">Определения</SectionTitle>
            </SubTitle>
            <NumberList>
                <ListItem>
                    <b>Веб-сайт</b> – веб-портал “safeboard.kaspersky.ru”.
                </ListItem>
                <ListItem>
                    <b>Пользователь (Вы) </b> – физическое лицо, которое использует Веб-сайт.
                </ListItem>
                <ListItem>
                    <b>«Лаборатория Касперского» </b> – АО «Лаборатория Касперского», Правообладатель.
                </ListItem>
                <ListItem>
                    <b>Администратор Веб-сайта </b> – представитель «Лаборатории Касперского», выполняющий
                    администрирование Веб-сайта.
                </ListItem>
            </NumberList>
            <Chapter>
                <Paragraph variant="p1">
                    Использование Веб-сайта означает ваше безоговорочное согласие с настоящими Условиями использования,
                    а также означает, что вы принимаете настоящие Условия использования. «Лаборатория Касперского»
                    оставляет за собой право без предварительных уведомлений в любое время и по своему усмотрению
                    изменять настоящие Условия использования, а также модифицировать Веб-сайт или любую его
                    функциональность. Продолжение использования Веб-сайта после внесения изменений означает Ваше
                    согласие с такими изменениями. Если вы не согласны с такими изменениями, Вы не должны использовать
                    Веб-сайт. Если вы не согласны с настоящими Условиями использования, Вы не должны использовать
                    Веб-сайт.
                </Paragraph>
            </Chapter>
            <SubTitle>
                <SectionTitle variant="h2">Права на интеллектуальную собственность</SectionTitle>
            </SubTitle>
            <Chapter>
                <Paragraph variant="p1">
                    «Лаборатория Касперского» является владельцем всех прав на Веб-сайт, включая как исключительные
                    права, так и другие применимые виды прав. «Лаборатория Касперского» имеет все необходимые права,
                    включая права на интеллектуальную собственность, и лицензии на опубликованные на Веб-сайте материалы
                    и информацию, включая, но не ограничиваясь текстовыми, графическими, фото-, видео- и
                    аудиоматериалами, а также программами для ЭВМ независимо от их вида. Данное соглашение не дает Вам
                    никаких прав на использование объектов интеллектуальной собственности, включая товарные знаки и
                    знаки обслуживания «Лаборатории Касперского», за исключением переданных Вам прав «Лабораторией
                    Касперского».
                </Paragraph>
            </Chapter>
            <SubTitle>
                <SectionTitle variant="h2">Общие условия</SectionTitle>
            </SubTitle>
            <NumberList>
                <ListItem>
                    Данный Веб-сайт предназначен для ознакомления с описанием структуры программы стажировок
                    «Лаборатории Касперского», а также заполнения и отправки заявки на участие в отборе.
                </ListItem>
                <ListItem>
                    «Лаборатория Касперского» предоставляет Вам право безвозмездно использовать Веб-сайт в пределах
                    функциональности Веб-сайта при условии соблюдения Вами всех ограничений и условий использования
                    Веб-сайта, указанных в настоящих Условиях использования.
                </ListItem>
                <ListItem>
                    Представители «Лаборатории Касперского» имеют право подтвердить или отклонить заявку Пользователя на
                    участие в отборе на стажировку и сообщить результаты Пользователю посредством электронной почты,
                    телефонного звонка или других средств связи.
                </ListItem>
                <ListItem>
                    В случае нарушения Пользователем настоящих Условий использования Администратор Веб-сайта имеет право
                    отказать в участии в отборе на программу стажировок.
                </ListItem>
                <ListItem>
                    Пользователь обязуется соблюдать все применимые законы, связанные с использованием Веб-сайта,
                    включая, но не ограничиваясь всеми применимыми законами, касающимися мошенничества через электронную
                    почту, несанкционированного получения доступа к устройству, кражи личных данных, банковского
                    мошенничества, мошенничества с использованием электронных средств коммуникации, компьютерного
                    мошенничества, рассылок спама, а также обеспечения конфиденциальности, охраны товарных знаков и
                    авторского права. Ваш доступ к Веб-сайту будет запрещен в случае, если «Лаборатория Касперского»
                    установит, что Вы не используете Веб-сайт в соответствии с условиями использования.
                </ListItem>
                <ListItem>
                    Запрещается передавать третьим лицам информацию, которая является конфиденциальной информацией
                    «Лаборатории Касперского», а именно содержимое заданий, получаемых в процессе отбора на стажировку.
                    Пользователь несёт полную ответственность за весь ущерб, причиненный им «Лаборатории Касперского»,
                    Веб-сайту или третьим лицам, возникший вследствие намеренной или ненамеренной передачи Пользователем
                    конфиденциальной информации «Лаборатории Касперского» третьим лицам.
                </ListItem>
                <ListItem>
                    При создании заявки на участие Пользователь обязан указывать действительные имя, фамилию, телефон,
                    адрес электронной почты и другие данные, указанные в Положении о конфиденциальности, в противном
                    случае Администратор Веб-сайта вправе отказать в продолжении отбора на стажировку.
                </ListItem>
            </NumberList>
            <SubTitle>
                <SectionTitle variant="h2">Ограничения</SectionTitle>
            </SubTitle>
            <Chapter>
                <Typography variant="p1">Пользователю Веб-сайта запрещается:</Typography>
            </Chapter>
            <NumberList>
                <ListItem>
                    Добавлять на Веб-сайт заведомо ложную информацию о себе, сообщать заведомо ложную информацию о себе
                    в ходе коммуникации с Администратором Веб-сайта.
                </ListItem>
                <ListItem>
                    Тестировать Веб-сайт с целью проверки на наличие уязвимостей без явного разрешения администратора
                    Веб-сайта, совершать какие-либо действия, которые могут вывести из строя, избыточно нагрузить или
                    негативно повлиять на нормальную работу Веб-сайта, например, атаки типа отказа в обслуживании или
                    отказа в отображении страниц Веб-сайта.
                </ListItem>
            </NumberList>
            <SubTitle>
                <SectionTitle variant="h2">Ограничение ответственности</SectionTitle>
            </SubTitle>
            <Chapter>
                <Paragraph variant="p1">
                    Веб-сайт может содержать ссылки на сторонние веб-сайты или ресурсы. «Лаборатория Касперского»
                    предоставляет такие ссылки только для вашего удобства и не несет ответственности за их содержание
                    или предоставляемые на них или доступные на них ресурсы или ссылки на продукты или услуги.
                    <br />
                    ВЕБ-САЙТ ПРЕДОСТАВЛЯЕТСЯ «КАК ЕСТЬ» БЕЗ ГАРАНТИЙ И УСЛОВИЙ (ВЫРАЖАЕМЫХ В ЯВНОЙ ИЛИ В ПОДРАЗУМЕВАЕМОЙ
                    ФОРМЕ) ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЙ, КОММЕРЧЕСКОЕ КАЧЕСТВО, ПРИГОДНОСТЬ ДЛЯ ОПРЕДЕЛЕННЫХ ЦЕЛЕЙ ИЛИ
                    НЕНАРУШЕНИЯ ПРАВ ТРЕТЬИХ ЛИЦ. В МАКСИМАЛЬНОЙ СТЕПЕНИ, ДОПУСКАЕМОЙ ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, НИ
                    ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ ЛАБОРАТОРИЯ КАСПЕРСКОГО НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА КАКИЕ-ЛИБО УБЫТКИ
                    И/ИЛИ УЩЕРБ, ВОЗНИКАЮЩИЕ ИЛИ КАКИМ-ЛИБО ОБРАЗОМ СВЯЗАННЫЕ С ВЕБ-САЙТОМ ИЛИ ЕГО ИСПОЛЬЗОВАНИЕМ.
                </Paragraph>
            </Chapter>
            <SubTitle>
                <SectionTitle variant="h2">Применимое законодательство</SectionTitle>
            </SubTitle>
            <Chapter>
                <Paragraph variant="p1">
                    Настоящее соглашение регулируется в соответствии с законодательством Российской Федерации.
                </Paragraph>
            </Chapter>
            <SubTitle>
                <SectionTitle variant="h2">Поддержка</SectionTitle>
            </SubTitle>
            <Chapter>
                <Paragraph variant="p1">
                    Если у вас есть какие-либо вопросы или предложения относительно работы Веб-сайта, пишите на
                    адрес:&nbsp;
                    <Anchor href="mailto:safeboard@kaspersky.com" target="_blank" rel="noreferrer">
                        safeboard@kaspersky.com
                    </Anchor>
                    <br />© 2020 АО «Лаборатория Касперского»
                    <br />
                    Дата последних изменений: 19.03.2024
                </Paragraph>
            </Chapter>
        </BaseWrapper>
    </CWrap>
);

export default Tou;
