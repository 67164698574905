import styled from 'styled-components';
import { mediaMd, mediaSm, mediaXs } from 'theme/theme-helper-selectors';

export const IntroImage = styled.img`
    position: absolute;
    top: 1%;
    right: 14%;
    z-index: -2;

    @media (${mediaMd}) {
        height: 700px;
        top: 15%;
        right: 10%;
    }

    @media (${mediaSm}) {
        height: 700px;
        top: 15%;
        right: 0%;
    }

    @media (${mediaXs}) {
        height: 400px;
        top: 41%;
        right: 0%;
    }

    @media only screen and (max-width: 400px) {
        right: -10%;
    }
`;

export const Snowboarder = styled.img`
    position: absolute;
    top: -275px;
    left: -150px;

    @media (${mediaSm}) {
        width: 290px;
        top: auto;
        bottom: -345px;
        z-index: 5;
        left: 20px;
    }
`;

export const Kuma = styled.img`
    position: absolute;
    right: -180px;
    bottom: 50px;

    @media (${mediaSm}) {
        width: 180px;
        right: -82px;
        bottom: auto;
        top: 150px;
        transform: rotate(-50deg);
    }
`;
