import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { HeaderNav } from './header-nav/HeaderNav';
import { HeaderHamburger } from './header-hamburger/HeaderHamburger';
import { HeaderRoot, HeaderWrapper, Logo } from './styled';
import { HeaderMenu } from 'types';
import { BaseWrapper } from 'styles/common';

export interface HeaderProps {
    links: HeaderMenu[];
}

const Header: FC<HeaderProps> = (props) => (
    <HeaderRoot>
        <BaseWrapper>
            <HeaderWrapper>
                <Link to="/" onClick={() => window.scroll(0, 0)}>
                    <Logo src={process.env.PUBLIC_URL + '/assets/icons/safeboard__is_white.png'} alt="Safeboard logo" />
                </Link>
                <HeaderNav {...props} />
                <HeaderHamburger />
            </HeaderWrapper>
        </BaseWrapper>
    </HeaderRoot>
);

export default Header;
