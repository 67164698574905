import styled from 'styled-components';
import { headerHeight, mediaSm, transitionBase } from '../../../../theme/theme-helper-selectors';

export const Nav = styled.nav<{ isHidden: boolean }>`
    transition: ${transitionBase};

    @media (${mediaSm}) {
        display: flex;
        align-self: center;
        position: fixed;
        z-index: 19;
        flex-direction: column;
        width: 100%;
        height: calc(-${headerHeight} + 100vh);
        top: ${({ isHidden }) => (isHidden ? '-120vh' : '64px')};
        left: 0px;
        right: 0px;
        background-color: ${({ theme }) => theme.palette.background};
        gap: 16px;
    }
`;
