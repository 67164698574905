export const REG_TYPE_RESPONSE: Record<string, string> = {
    REG_SUCCESS:
        'Поздравляем! Вы успешно зарегистрировались. Сейчас вам на почту придет письмо о дальнейших шагах и письмо о назначении онлайн-тестирования на платформе Teachbase',
    IS_SERVER_ERROR:
        'Наш сервер временно недоступен. Проверьте, пожалуйста, соединение с интернетом и попробуйте оправить заявку на регистрацию еще раз.',
    COURSE_DOESNT_HAVE_INTO_FORM_SETTINGS: 'Курс не назначен в настройках администратора',
    EMAIL_AND_FIRSTNAME_ARE_REQUIRED: 'Имя и email являются обязательными полями',
    USER_IS_EXISTING_ON_THIS_COURSE: 'Вы уже проходили регистрацию на данный курс',
    YOU_CAN_NOT_USING_AUTH: 'Ваша заявка принята',
    CAPTCHA_IS_NOT_VALID: 'Капча не сработала',
    LTI_ERROR: 'Ошибка настроек LTI',
};
