import React, { ReactElement } from 'react';
import { HamburgerButton, HamburgerLine } from './styled';
import { useMobileMenuContext } from 'contexts/mobile-menu.context';

export const HeaderHamburger = (): ReactElement => {
    const { isMobileMenuOpened, toggleMobileMenu } = useMobileMenuContext();

    return (
        <HamburgerButton
            title="Menu"
            type="button"
            isMobileMenuOpened={isMobileMenuOpened}
            onClick={() => toggleMobileMenu(!isMobileMenuOpened)}
        >
            <HamburgerLine />
            <HamburgerLine />
            <HamburgerLine />
        </HamburgerButton>
    );
};
