import { Modal, ModalBody, ModalElement } from 'kl-b2c-ui-kit';
import React, { LegacyRef, PropsWithChildren, forwardRef } from 'react';

const PrimaryModal = forwardRef<ModalElement, PropsWithChildren>(({ children }, ref) => (
    <Modal
        ref={ref as LegacyRef<ModalElement>}
        size={'md'}
        showClose
        styles={{
            borderRadius: '16px',
            top: '50%',
            transform: 'translateY(-50%)',
            '@media screen and (max-width: 767px)': {
                borderRadius: 'initial',
            },
        }}
    >
        <ModalBody
            styles={{
                padding: '32px 24px',
                '@media screen and (max-width: 767px)': {
                    padding: '50px 16px 32px',
                },
            }}
        >
            {children}
        </ModalBody>
    </Modal>
));

export default PrimaryModal;
