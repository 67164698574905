export const toDictionary = <T>(items: T[], keyProperty: string | ((item: T) => string)): Record<string, T> => {
    if (!items || !items.length) {
        return {};
    }

    if (typeof keyProperty === 'string') {
        // eslint-disable-next-line no-prototype-builtins
        const someItemHasNoSuchProperty = items.some((item) => !Object(item).hasOwnProperty(keyProperty));
        if (someItemHasNoSuchProperty) {
            return {};
        }

        // @ts-ignore
        return items.reduce((acc, curr) => ({ ...acc, [curr[keyProperty]]: curr }), {});
    }

    if (typeof keyProperty === 'function') {
        const keyGetter = keyProperty as (item: T) => string;
        return items.reduce((acc, curr) => ({ ...acc, [keyGetter(curr)]: curr }), {});
    }
    return {};
};
