import { LS_KEYS } from 'consts';
import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { StorageService } from 'services';
import { AppSettings } from 'types';

interface SettingsContext {
    settings: AppSettings | null;
}

const SettingsContext = createContext<SettingsContext>({ settings: null });

const SettingsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const storageService = new StorageService();
    const [settings, setSettings] = useState<AppSettings | null>(null);

    useEffect(() => {
        fetch('/settings/settings.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((settings: AppSettings) => {
                storageService.setItem<AppSettings>(LS_KEYS['appSettings'], settings);
                setSettings(settings);
            })
            .catch((e: Error) => {
                throw new Error(`Cannot load app settings: ${e.message}`);
            });
    }, []);

    const memoValue = useMemo(
        () => ({
            settings,
        }),
        [settings]
    );

    return <SettingsContext.Provider value={memoValue}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => useContext(SettingsContext);

export default SettingsProvider;
