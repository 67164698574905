import styled, { css } from 'styled-components';
import { HamburgerLine } from './line';
import { colorMenuBackground, mediaSm } from '../../../../theme/theme-helper-selectors';

export const HamburgerButton = styled.button<{
    isMobileMenuOpened: boolean;
}>`
    display: none;
    background: ${colorMenuBackground};
    border: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 20;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border: none;
    gap: 3px;

    @media (${mediaSm}) {
        display: flex;

        ${({ isMobileMenuOpened }) =>
            isMobileMenuOpened &&
            css`
                ${HamburgerLine}:nth-child(1) {
                    transform: translate(0, 7px) rotate(45deg);
                }

                ${HamburgerLine}:nth-child(2) {
                    width: 0;
                    opacity: 0;
                }

                ${HamburgerLine}:nth-child(3) {
                    transform: translate(0, -5px) rotate(-45deg);
                }
            `}
    }
`;
