import styled, { css } from 'styled-components';
import { Typography } from 'kl-b2c-ui-kit';
import { mediaXs } from 'theme/theme-helper-selectors';

export const Form = styled.form<{ gap: string; loading?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap};
    height: 100%;
    ${({ loading }) =>
        loading &&
        css`
            pointer-events: none;
            opacity: 0.6;
        `}
`;

export const Disclaimer = styled(Typography).attrs({
    variant: 'p6',
})`
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    a {
        font-weight: 600;
    }
`;

export const FieldRow = styled.div`
    position: relative;
`;

export const FieldRowDouble = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media (${mediaXs}) {
        flex-direction: column;
    }

    ${FieldRow} {
        width: 100%;
    }
`;

export const Error = styled(Typography).attrs({
    variant: 'p5',
})`
    color: ${({ theme }) => theme.palette.energyRed};
`;

export const Hidden = styled.div`
    display: none;
`;

export const SubmitModalRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding: 20px 0;
`;

export const SubmitModalMessage = styled.span`
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
`;
