import { gql } from '@apollo/client';

export const GET_FAQ_QUESTIONS_GQL = gql`
    query ($page: Int!, $size: Int!, $subject: QuestionType) {
        questions {
            get(filter: { page: $page, size: $size, subject: $subject, sortByCreationDate: false }) {
                count
                items {
                    creationDate
                    id
                    question
                    answer
                    subject
                    number
                    visible
                }
            }
        }
    }
`;

export default GET_FAQ_QUESTIONS_GQL;
