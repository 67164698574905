import styled from 'styled-components';
import { mediaSm } from 'theme/theme-helper-selectors';

export const Logo = styled.img`
    padding: 17px 15px 17px 0;

    @media (${mediaSm}) {
        padding: 0;
    }
`;
