import { FaqItem, FaqType } from 'types';

export const EMAIL_HELP = 'mailto:safeboard@kaspersky.com';

export const SERVICE_DESK_QUESTIONS: Omit<FaqItem, 'creationDate'>[] = [
    {
        id: '1',
        answer: 'Участвовать в отборе могут все, кто: </br> <ul><li>Проживает в Москве либо Московской области, так как стажировка проходит в Московском офисе; </li><li>Являются текущими студентами вуза любого курса или участниками Школы 21, заканчивающими обучение в 2025 году или позже;</li><li>Готовы уделять работе хотя бы 30 часов в неделю. </li></ul> <br /> Вуз и специальность не имеют значения — прежде всего, мы оцениваем кандидатов по итогам прохождения онлайн-тестов. ',
        number: 1,
        question: 'Кто может участвовать?',
        visible: true,
        subject: FaqType.Selection,
    },
    {
        id: '2',
        answer: 'Тебя ждут следующие этапы отбора: подача заявки, тестирование на знание теории, запись видеоинтервью, знакомство с командами на очном мероприятии и собеседование. Перед каждым этапом мы будем направлять подробную инструкцию, но если будут дополнительные вопросы, пиши нам на <a href="mailto:safeboard@kaspersky.com" rel="noreferrer">safeboard@kaspersky.com</a>.',
        number: 2,
        question: 'Как будет проходить отбор?',
        visible: true,
        subject: FaqType.Selection,
    },
    {
        id: '3',
        answer: 'Тебе нужно будет за ограниченное количество времени ответить на тестовые вопросы. В большинстве вопросов будут варианты ответа, но могут попасться и вопросы с открытым вводом. ',
        number: 3,
        question: 'Как проходит этап онлайн-тестирования?',
        visible: true,
        subject: FaqType.Selection,
    },
    {
        id: '4',
        answer: 'На этапе видеоинтервью тебе нужно будет записать ответы на вопросы. На экране будет появляться вопрос, будет несколько секунд чтобы его прочитать и дальше автоматически начнется запись. Перезаписать ответы нельзя, поэтому лучше приступать к этапу когда тебе ничего не мешает. Этот этап помогает получить общее впечатление о кандидате и занимает у него всего 15-30 минут времени. Как правило, на этом этапе команды хотят узнать о том, почему участник заинтересован в направлении и что ожидает от стажировки, а также какими базовыми техническими знаниями обладает.',
        number: 4,
        question: 'Что такое VCV (видеоинтервью)?',
        visible: true,
        subject: FaqType.Selection,
    },
    {
        id: '5',
        answer: 'На деловой игре нужно будет выполнить задание в команде. Не волнуйся, специальной подготовки к заданию не требуется, но можем дать совет: не забывай активно участвовать в командной работе и проявляться, чтобы мы смогли оценить твою работу.',
        number: 5,
        question: 'Что будет на очном мероприятии?',
        visible: true,
        subject: FaqType.Selection,
    },
    {
        id: '6',
        answer: 'Сейчас открыт отбор на другие направления стажировки: разработку, тестирование, DevOps, системный анализ, анализ защищенности и др. Подать заявку и ознакомиться с направлениями можно на сайте <a href="https://kas.pr/8ggs" rel="noreferrer" target="_blank">https://kas.pr/8ggs</a>',
        number: 6,
        question: 'Что делать, если мне не подходит Service Desk?',
        visible: true,
        subject: FaqType.Selection,
    },
    {
        id: '7',
        answer: 'Стажеры могут выбрать оптимальное количество часов для работы в неделю: от 30 до 35 часов во время учебы и до 40 часов — летом. Что касается рабочих часов — мы работаем с 10:00 до 18:30.',
        number: 7,
        question: 'Можно ли совмещать стажировку с учебой?',
        visible: true,
        subject: FaqType.Internship,
    },
    {
        id: '8',
        answer: 'Стажировка оплачивается: зарплата прямо пропорциональна выбранной часовой ставке. Зарплату озвучиваем перед собеседованиями, но можем гарантировать, что она не зависит от выбранного направления стажировки. Мы любим заботиться о наших стажерах, поэтому предлагаем ежемесячную компенсацию питания, бесплатный спортзал в офисе и другие бонусы. ',
        number: 8,
        question: 'Стажировка оплачивается? Какие условия работы?',
        visible: true,
        subject: FaqType.Internship,
    },
    {
        id: '9',
        answer: 'Конечно, при изменении учебной нагрузки можно менять количество часов в ходе стажировки, но мы ожидаем, что ты сможешь работать 30-35 часов в неделю.',
        number: 9,
        question: 'Можно ли поменять количество часов, уделяемых работе?',
        visible: true,
        subject: FaqType.Internship,
    },
    {
        id: '10',
        answer: 'Решение по каждому стажеру принимается в зависимости от нескольких факторов: обратная связь по стажеру от команды, пожелания стажера по направлению дальнейшего развития, наличие подходящих вакансий. Наша цель — чтобы исход устраивал и стажера, и команду, поэтому вариантов множество, в том числе прием в штат еще до официального окончания стажировки.',
        number: 10,
        question: 'Что меня ждет после окончания стажировки?',
        visible: true,
        subject: FaqType.Internship,
    },
];
