import HomePage from 'containers/pages/home-page/HomePage';
import { MobileMenuStateProvider } from 'contexts';
import { Loader } from 'kl-b2c-ui-kit';
import React, { FC, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DefaultTheme, withTheme } from 'styled-components';
import App from '../app/App';
const Tou = lazy(() => import('containers/pages/tou/Tou'));
const PrivacyPolicy = lazy(() => import('containers/pages/tou/PrivacyPolicy'));
const ServiceDesk = lazy(() => import('containers/pages/service-desk/ServiceDesk'));

interface Props {
    theme: DefaultTheme;
}

const AppRouter: FC<Props> = ({ theme }) => (
    <BrowserRouter>
        <MobileMenuStateProvider>
            <Suspense fallback={<Loader variant="fixed" size="large" transparent customColor={theme.palette.green} />}>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/terms-of-use" element={<Tou />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/service-desk" element={<ServiceDesk />} />
                    </Route>
                </Routes>
            </Suspense>
        </MobileMenuStateProvider>
    </BrowserRouter>
);

export default withTheme(AppRouter);
